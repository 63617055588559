/* error notice */
.notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #740574;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5bcdb;
}
