@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: unset;
}

ul {
  margin: 1.5rem;
  list-style: none;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  color: unset;
  cursor: pointer;
}

/* general */
body {
  font-family: "Inter", "sans-serif";
  background-color: #000000;
}

.page {
  padding: 1rem;
  margin-top: 5rem;
}

.container {
  margin: 0 auto;
  max-width: 600px;
}

.title {
  color: #ffffff;
  font-size: 2.5rem;
}

/* header */
#header {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
}

#header .title {
  margin: 0;
  padding: 0 1rem;
  color: #ffffff;
}

#header .auth-options {
  height: 100%;
  display: flex;
}

#header .auth-options button {
  padding: 0 1rem;
  background-color: #212124;
  color: #ffffff;
  font-size: 1.1rem;
}

#header .logo {
  height: 3.5rem;
  padding: 0 1rem;
}

#header .auth-options button:hover {
  background-color: #000000;
}

.btn-submit {
  color: #000000;
}

/* form */
.form label,
.form input {
  display: block;
}

.form input {
  margin-bottom: 1rem;
}

.form label {
  margin-bottom: 0.2rem;
  color: #ffffff;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="time"],
.form input[type="url"],
.form textarea[type="text"] {
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  background-color: #f0f0f0;
  font-size: 1.1rem;
}

.form input:focus {
  border: 1px solid #50a4e9;
  box-shadow: inset 0px 0px 5px rgba(38, 142, 228, 0.5);
  outline: none;
}

.form input[type="submit"] {
  padding: 0.3rem 0.5rem;
  border: 1px solid #757574;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 1.1rem;
}

/* error notice */
.error-notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}

.error-notice button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

/* media queries */
@media screen and (max-width: 550px) {
  #header .title {
    font-size: 0.5rem;
  }

  #header .auth-options button {
    font-size: 0.8rem;
  }

  #header .logo {
    height: 2.5rem;
    padding: 0;
  }
}
