@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.footer-container {
  background-color: #000000;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 24px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.img {
  width: 10%;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

/* error notice */
.notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #740574;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5bcdb;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: unset;
}

ul {
  margin: 1.5rem;
  list-style: none;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  color: unset;
  cursor: pointer;
}

/* general */
body {
  font-family: "Inter", "sans-serif";
  background-color: #000000;
}

.page {
  padding: 1rem;
  margin-top: 5rem;
}

.container {
  margin: 0 auto;
  max-width: 600px;
}

.title {
  color: #ffffff;
  font-size: 2.5rem;
}

/* header */
#header {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
}

#header .title {
  margin: 0;
  padding: 0 1rem;
  color: #ffffff;
}

#header .auth-options {
  height: 100%;
  display: flex;
}

#header .auth-options button {
  padding: 0 1rem;
  background-color: #212124;
  color: #ffffff;
  font-size: 1.1rem;
}

#header .logo {
  height: 3.5rem;
  padding: 0 1rem;
}

#header .auth-options button:hover {
  background-color: #000000;
}

.btn-submit {
  color: #000000;
}

/* form */
.form label,
.form input {
  display: block;
}

.form input {
  margin-bottom: 1rem;
}

.form label {
  margin-bottom: 0.2rem;
  color: #ffffff;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="time"],
.form input[type="url"],
.form textarea[type="text"] {
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  background-color: #f0f0f0;
  font-size: 1.1rem;
}

.form input:focus {
  border: 1px solid #50a4e9;
  box-shadow: inset 0px 0px 5px rgba(38, 142, 228, 0.5);
  outline: none;
}

.form input[type="submit"] {
  padding: 0.3rem 0.5rem;
  border: 1px solid #757574;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 1.1rem;
}

/* error notice */
.error-notice {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}

.error-notice button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

/* media queries */
@media screen and (max-width: 550px) {
  #header .title {
    font-size: 0.5rem;
  }

  #header .auth-options button {
    font-size: 0.8rem;
  }

  #header .logo {
    height: 2.5rem;
    padding: 0;
  }
}

